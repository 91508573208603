const options = [
  {
    "to": "/",
    "title": "Projects"
  },
  {
    "to": "/about",
    "title": "About Me"
  },
  // {
  //   "to": "/work",
  //   "title": "My Work"
  // },
  {
    "to": "/chat",
    "title": "Let's Chat"
  }
]

export default options
