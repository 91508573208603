import TTS from '../images/skills/t-ts.jpg'
import TReact from '../images/skills/t-react.jpg'
import TNode from '../images/skills/t-node.jpg'
import TRedux from '../images/skills/t-redux.jpg'
import TNextjs from '../images/skills/t-nextjs.jpg'
import TJest from '../images/skills/t-jest.jpg'
import TVuejs from '../images/skills/t-vuejs.jpg'
import TSequelize from '../images/skills/t-sequelize.jpg'

import TDjango from '../images/skills/t-django.jpg'
import TFlask from '../images/skills/t-flask.jpg'
import TScikit from '../images/skills/t-scikit.jpg'
import TNumpy from '../images/skills/t-numpy.jpg'
import TPandas from '../images/skills/t-pandas.jpg'
import TJupyter from '../images/skills/t-jupyter.jpg'
import TMatplotlib from '../images/skills/t-matplotlib.jpg'

import TMySQL from '../images/skills/t-mysql.jpg'
import TPostgreSQL from '../images/skills/t-postgresql.jpg'
import TMongoDB from '../images/skills/t-mongodb.jpg'
import TSqlite from '../images/skills/t-sqlite.jpg'
import TRedis from '../images/skills/t-redis.jpg'

import TGraphQL from '../images/skills/t-graphql.jpg'
import TGIT from '../images/skills/t-git.jpg'
import TDocker from '../images/skills/t-docker.jpg'
import TTerraform from '../images/skills/t-terraform.jpg'
import TAWS from '../images/skills/t-aws.jpg'

import TFlutter from '../images/skills/t-flutter.jpg'
import TApple from '../images/skills/t-apple.jpg'
import TAndroid from '../images/skills/t-android.jpg'
import TFirebase from '../images/skills/t-firebase.jpg'

import THTML from '../images/skills/t-html.jpg'
import TCSS from '../images/skills/t-css.jpg'
import TSASS from '../images/skills/t-sass.jpg'
import TBootstrap from '../images/skills/t-bootstrap.jpg'
import TTailwind from '../images/skills/t-tailwind.jpg'

const skillslist = [
  {
    "title": "JavaScript",
    "color": "#eeae4a",
    "text": "#000",
    "skills": [
      {"name": "TypeScript", "logo": TTS},
      {"name": "Node.js", "logo": TNode},
      {"name": "React.js", "logo": TReact},
      {"name": "Next.js", "logo": TNextjs},
      {"name": "Redux", "logo": TRedux},
      {"name": "Jest", "logo": TJest},
      {"name": "Sequelize", "logo": TSequelize},
      {"name": "Vue.js", "logo": TVuejs},
    ]
  },
  {
    "title": "Python",
    "color": "#004792",
    "text": "#fff",
    "skills": [
      {"name": "Django", "logo": TDjango},
      {"name": "Flask", "logo": TFlask},
      {"name": "Jupyter", "logo": TJupyter},
      {"name": "Scikit-learn", "logo": TScikit},
      {"name": "Matplotlib", "logo": TMatplotlib},
      {"name": "Pandas", "logo": TPandas},
      {"name": "Numpy", "logo": TNumpy},
    ]
  },
  {
    "title": "Mobile App Development",
    "color": "#61b086",
    "text": "#000",
    "skills": [
      {"name": "Flutter", "logo": TFlutter},
      {"name": "React Native", "logo": TReact},
      {"name": "Apple", "logo": TApple},
      {"name": "Android", "logo": TAndroid},
      {"name": "Firebase", "logo": TFirebase},
    ]
  },
  {
    "title": "Databases",
    "color": "#aaa",
    "text": "#000",
    "skills": [
      {"name": "Postgres", "logo": TPostgreSQL},
      {"name": "MySQL", "logo": TMySQL},
      {"name": "MongoDB", "logo": TMongoDB},
      {"name": "SQLite", "logo": TSqlite},
      {"name": "Redis", "logo": TRedis},
    ]
  },
  {
    "title": "DevOps & Backend",
    "color": "#666",
    "text": "#fff",
    "skills": [
      {"name": "GraphQL", "logo": TGraphQL},
      {"name": "Git", "logo": TGIT},
      {"name": "AWS", "logo": TAWS},
      {"name": "Docker", "logo": TDocker},
      {"name": "Terraform", "logo": TTerraform},
    ]
  },
  {
    "title": "Frontend Development",
    "color": "#c84b36",
    "text": "#fff",
    "skills": [
      {"name": "HTML", "logo": THTML},
      {"name": "CSS", "logo": TCSS},
      {"name": "SASS", "logo": TSASS},
      {"name": "Bootstrap", "logo": TBootstrap},
      {"name": "Tailwind", "logo": TTailwind},
    ]
  },
]

export default skillslist
